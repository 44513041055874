<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">Role View</h4>
                <div>
                  <b-button variant="outline-red" squared @click="openDeleteModal" size="sm">Delete</b-button>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <div v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name <span class="text-red">*</span></label>
                <b-form-input v-model="roleData.name"
                              @blur="roleData.name.$touch()"></b-form-input>
              </b-col>
            </b-row>
            <b-row v-if="true" class="mb-3">
              <b-col cols="12">
                <br/>
                <h4
                    style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">
                  Tags</h4>
                <br/>

              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox-group id="checkbox-group-1"  v-model="selected">
                      <b-form-checkbox v-for="tag in tags" :key="tag.id" :value="tag" @change="setCheckBox(tag.id)">{{ tag.name }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>

              </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="submitUpdates" class="ml-2">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="userModal" hide-footer hide-header-close title="Delete User">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are you sure you want to delete <span
              class="font-weight-bold text-red text-center">{{ this.roleData.name }}</span>?</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="outline-red" @click="hideDeleteModal" squared class="mr-2">Cancel</b-button>
          <b-button variant="red" @click="removeRole" squared>Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>

  </div>
</template>
<style>
.primary {
  background-color: #ffcb14;
  color: #222222;
}

.primary:hover {
  color: #fff;
}

.page-item.active .page-link {
  z-index: 3;
  color: #222222;
  background-color: #ffcb14;
  border-color: #ffcb14;
}
</style>
<script>
import {mapMutations} from "vuex";
import api from "../../../api";
import sortTags from "@/sortAlgorithm";

export default {
  name: "userView",

  data: () => ({
    state: 'loading',
    tags_values: {},
    roleData: {
      id: 0,
      name: null,
      roleTagIds: [],
      displayTags: null
    },
    tags: [],
    selected: [],
  }),
  created() {

    this.setBreadcrumb([
      {
        text: 'Roles'
      },
      {
        text: 'Details'
      },
    ]);
    const st = (data) => {
      this.tags = data;
      sortTags(this.tags);

      this.tags.forEach(x => this.tags_values[x.name] = false)

      const s = (data) => {
        this.roleData = data;
        data.roleTagIds.forEach(x => { this.selected.push(this.tags.find(z => z.id === x)); });
        console.log(data);
        this.state = 'show';
      };

      const e = (msg) => {
        console.log(msg);
      };
      this.roleData.id = localStorage.getItem("view_role_id");
      api.getRoleID(localStorage.getItem("view_role_id"), s, e);
    };

    const et = (msg) => {
      console.log(msg);
    };
    api.getAllTags(st, et);


  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    submitUpdates() {
      var tagIds = [];

        this.selected.forEach((item) => {
          const result = item.id;
          tagIds.push(result)
        });
    this.roleData.roleTagIds = tagIds;

      const s = (data) => {
        console.log(data);
        this.$router.push ({path: '/admin/ManageRoles/SearchRole'})
      }

      api.UpdateRole(this.roleData, s, console.error)


    },

    setCheckBox(id){
      console.log(id)
    },

    removeRole() {
      var s = (data) => {
        console.log(data);
        this.hideDeleteModal();
        // window.location = '/admin/ManageRoles/SearchRole';
        this.$router.push({path: "/admin/ManageRoles/SearchRole"})
      };
      var e = (error) => {
        this.hideDeleteModal();
        this.makeToast(error.message);
      };

      api.DeleteRole(this.roleData, s, e);
    },

    makeToast(message) {
            this.$bvToast.toast(`${message}`, {
                title: 'Something went wrong.',
                autoHideDelay: 5000,
                appendToast: false
            })
        },


    goBackToSearch() {
      this.$router.push({path: '/admin/ManageRoles/SearchRole'})
    },


    openDeleteModal() {
      this.$bvModal.show('userModal')
    },


    hideDeleteModal() {
      this.$bvModal.hide('userModal')
    },
  },

}
</script>

<style scoped>

</style>